import {
    ScmIntegrationsApi,
    scmIntegrationsApiRef,
    ScmAuth,
} from '@backstage/integration-react';
import {
    AnyApiFactory, ApiRef, BackstageIdentityApi,
    configApiRef,
    createApiFactory, createApiRef, discoveryApiRef, oauthRequestApiRef, OpenIdConnectApi, ProfileInfoApi, SessionApi,
} from '@backstage/core-plugin-api';
import {OAuth2} from "@backstage/core-app-api";
// import {costInsightsApiRef} from '@backstage/plugin-cost-insights';
// import {CostInsightsClient} from "./utils/costs-insights";
// import {techRadarApiRef} from "@backstage/plugin-tech-radar";
// import {TechRadarClient} from "./lib/tech-radar";

export const keycloakOIDCAuthApiRef: ApiRef<
    OpenIdConnectApi & ProfileInfoApi & BackstageIdentityApi & SessionApi
> = createApiRef({
    id: 'auth.keycloak',
});

keycloakOIDCAuthApiRef

export const apis: AnyApiFactory[] = [
    // createApiFactory({
    //     api: costInsightsApiRef,
    //     deps: {},
    //     factory: () => new CostInsightsClient(),
    // }),
    // createApiFactory(techRadarApiRef, new TechRadarClient()),
    createApiFactory({
        api: keycloakOIDCAuthApiRef,
        deps: {
            discoveryApi: discoveryApiRef,
            oauthRequestApi: oauthRequestApiRef,
            configApi: configApiRef,
        },
        factory: ({discoveryApi, oauthRequestApi, configApi}) =>
            OAuth2.create({
                discoveryApi,
                oauthRequestApi,
                provider: {
                    id: 'keycloak',
                    title: 'Keycloak OIDC provider',
                    icon: () => null,
                },
                environment: configApi.getOptionalString('auth.environment'),
                defaultScopes: [
                    'openid',
                    'profile',
                    'email',
                ],
            }),
    }),
    createApiFactory({
        api: scmIntegrationsApiRef,
        deps: {configApi: configApiRef},
        factory: ({configApi}) => ScmIntegrationsApi.fromConfig(configApi),
    }),
    ScmAuth.createDefaultApiFactory(),
];
