import {githubAuthApiRef} from "@backstage/core-plugin-api";
import {keycloakOIDCAuthApiRef} from "./apis";
export const providers = [
    // {
    //     id: 'github-auth-provider',
    //     title: 'GitHub',
    //     message: 'Sign in using GitHub',
    //     apiRef: githubAuthApiRef,
    // },
    {
        id: 'keycloak',
        title: 'Keycloak',
        message: 'Sign in via Keycloak',
        apiRef: keycloakOIDCAuthApiRef,
    }
];